import React from "react";
import Navbar from '../../components/Navbar';
import Footer from '../../components/Footer';
import BreadCrumb from '../../components/BreadCrumb';

const HumanResourceManagementInformationSystem = () => {
    return (
        <div>
            <Navbar />
                <div class="container">
                    <BreadCrumb 
                        active_page="Human Resource Management Information System" 
                        prev_sub_page={{
                            'link':'/services',
                            'text':'Services'
                        }}
                        sub_page={{
                            'link':'/services/business-process',
                            'text':'Business Process'
                        }} 
                    />
                    <div class="inner-container">
                        <h2>Human Resource Management Information System</h2>             
                        <h3>Lets You Keep Track</h3>
                        <p>Standardize your HR tasks and processes and offer a more seamless, streamlined, and efficient coordination between employees and the organization.</p>
                        <h3>Develop, Engage, & Manage</h3>
                        <p>Automate your HR activities right from hiring, flawless digital onboarding, talent development, employee engagement and build robust workplace at every touchpoint.</p>
                        <h3>Window To More Effective Workforce</h3>
                        <ul>
                            <li>Workforce Planning</li>
                            <li>Recruitment</li>
                            <li>Learning & Development</li>
                            <li>Work Schedules</li>
                            <li>Leave Management</li>
                            <li>Performance Tracking</li>
                            <li>Payroll & Compliance</li>
                        </ul>
                        <h3>How We Do</h3>
                        <img alt="" src={require('../../assets/images/services/hrms.png')} className="d-block m-auto mw-70" />
                        <h3>Choose Your HRIS Type</h3>
                        <img alt="" src={require('../../assets/images/services/hrms-2.png')} className="d-block m-auto mw-70" />
                        <h3>Work Your Way</h3>
                        <ul>
                            <li>Paperless onboarding</li>
                            <li>Make data driven decisions</li>
                            <li>Enhance user experiences</li>
                            <li>Role based access</li>
                            <li>Succession planning</li>
                        </ul>
                    </div>
                </div>
            <Footer />
        </div>
    );
};

export default HumanResourceManagementInformationSystem;