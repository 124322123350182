import React from "react";
import Navbar from '../../components/Navbar';
import Footer from '../../components/Footer';
import BreadCrumb from '../../components/BreadCrumb';
import { Link, useNavigate,useLocation } from 'react-router-dom';
import '../About.css';
import { FiArrowRight } from "react-icons/fi";

const ApplicationDevelopment = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const { sub_page,title,url } = location.state || {};
    console.log(sub_page,title,url,location?.state)
    return (
        <div>
            <Navbar />
            <section className="about-breadcrumb">
                <BreadCrumb
                    active_page="Application Development"
                    prev_sub_page={{
                        'link': '/services',
                        'text': 'Services'
                    }}
                    sub_page={sub_page ? {
                        'link': url,
                        'text': title
                    } : null}
                />
            </section>
            <section className="ecosystem">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6" style={{alignItems:"flex-start"}}>
                            <div className="ecosystem-card" style={{marginLeft:"50px"}}>
                                <h5>Build Solutions That Grow Along With You</h5>
                                <p>Navigate through your business challenges with latest tech
                                    trends. We are agile implementers and we adopt the increm
                                    -ental and iterative process of application development.</p>
                                <div className="image">
                                    <img src={require('../../assets/images/services/BuildSolutions.png')} alt="BuildSolutions" />
                                </div>
                            </div>
                            <div className="ecosystem-card ecosystem-card-1">
                                <h5>Integrated Development Envirnoment</h5>
                                <p>Always build things that work together. We create a framework
                                    that enables faster development and productivity. This adds
                                    more precision to our work while streamlining the entire
                                    process.</p>
                                <div className="image">
                                    <img src={require('../../assets/images/services/IntegratedDevelopment.png')} alt="action oriented" />
                                </div>
                            </div>
                            <div className="ecosystem-card" style={{marginLeft:"50px"}}>
                                <h5>Reliable & Rapid</h5>
                                <p>Streamlined delivery with cost effective engagements those
                                    would match your goals, timelines, and budgets.</p>
                                <div className="image">
                                    <img src={require('../../assets/images/services/ReliableRapid.png')} alt="action oriented" />
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="image-right" style={{maxWidth:"579px"}}>
                                    <img src={require('../../assets/images/services/ApplicationDevelopmentBanner.png')} alt="action oriented" />
                                </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="ecosystem-ao">
                <div>
                    <h4>Deliver Incrementally, Instead Of All At Once</h4>
                    <p>We follow the agile development methodology which concentrates on</p>
                </div>
                <div className="container">
                   <ul className="deliver-list">
                    <li>Creating functional capabilities that are aggregated 
                    to fulfill the business requirements</li>
                    <li>Scrum framework that guides the application development</li>
                    <li>Sprints to deliver functions within a finite time</li>
                   </ul>
                   </div>
            </section>
            <section className="what-we-do">
                <div className="container">
                   <div className="image-right">
                   <img src={require('../../assets/images/services/application-development.png')} alt="BuildSolutions" />
                   </div>
                </div>
            </section>
            <section className="newproject">
                <div className="ellipse_top" style={{ backgroundColor: 'white' }}></div>
                <div class="container">
                    <p>Let's Work Together</p>
                    <h3>Turn your vision<br /> into reality</h3>
                    <button className="btn btn-default" style={{ backgroundColor: 'white' }}><Link to="/contact-us">Let's collaborate</Link></button>
                </div>
                <div className="ellipse_bottom"></div>
            </section>
            <Footer />
        </div>
    );
};

export default ApplicationDevelopment;