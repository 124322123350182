import React from "react";
import Navbar from '../../components/Navbar';
import Footer from '../../components/Footer';
import BreadCrumb from '../../components/BreadCrumb';
import { Link } from "react-router-dom";
const SocialNetworking = () => {
    return (
        <div>
            <Navbar />
            <section className="about-breadcrumb">
                <BreadCrumb
                    active_page="Social Networking"
                    prev_sub_page={{
                        'link': '/services',
                        'text': 'Services'
                    }}
                    sub_page={{
                        'link': '/services/business-process',
                        'text': 'Business Process'
                    }}
                />
            </section>
            <section className="right-sized">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-md-7">
                            <div className="ecosystem-card padded ms-desktop">
                                <h5>Stand Apart From Crowd</h5>
                                <p>Get the best social network development services for your business fully customized to your niche in line with technology standards.</p>
                            </div>
                            <div className="ecosystem-card padded">
                                <h5>Adding A Social Dimension</h5>
                                <p>We incorporate technology mediated social interactions to our development practices, and techniques thereby adding a social dimension to customer interactions.</p>
                            </div>
                            <div className="ecosystem-card padded ms-desktop">
                                <h5>Not Just Networking</h5>
                                <p>The network platforms we build doesn’t just involve user interactions and sharing of common interests but one can promote their brands, sell products, run campaigns, & so on</p>
                            </div>
                        </div>
                        <div className="col-md-5">
                            <img src={require('../../assets/images/services/stand-apart.webp')} alt="right sized" className="img-fluid" />
                        </div>
                    </div>
                </div>
            </section>
            <section className="banner-text metrics">
                <div className="container">
                    <h2>Metrics Involved In Development</h2>
                    <div className="grid_container_3">
                        <div className="grid-item">Security</div>
                        <div className="grid-item">User Interaction</div>
                        <div className="grid-item">User Management</div>
                        <div className="grid-item">In App Communication</div>
                        <div className="grid-item">Geolocation </div>
                        <div className="grid-item">Data Types</div>
                        <div className="grid-item">Date Sources Integration</div>
                        <div className="grid-item">3rd Party API Integration</div>
                        <div className="grid-item">Payment System Integration</div>
                    </div>
                </div>
            </section>
            <section className="sn-how">
                <div className="container">
                    <h2 className="text-center">How We Do</h2>
                    <div className="row">
                        <div className="col-md-6">
                            <img src={require('../../assets/images/services/sn-how.webp')} alt=" how we do it" />
                        </div>
                        <div className="col-md-6">
                            <ul className="blue-arrows">
                                <li>Define Your Target Audience</li>
                                <li>Develop Strategy</li>
                                <li>Design & Development</li>
                                <li>Publishing</li>
                                <li>Support</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </section>
            <section className="benefits education">
                <div className="container">
                    <h2>Select Network That Suits Your Niche</h2>
                    <div className="grid_container_3">
                        <div className="grid-item">Informational</div>
                        <div className="grid-item">Educational</div>
                        <div className="grid-item">E-Commerce</div>
                        <div className="grid-item">Multimedia </div>
                        <div className="grid-item">Social Connections</div>
                        <div className="grid-item">Dating</div>
                    </div>
                </div>
            </section>
            <section className="newproject">
                <div className="ellipse_top"></div>
                <div class="container">
                    <p>Let's Work Together</p>
                    <h3>Turn your vision<br /> into reality</h3>
                    <button className="btn btn-default" style={{ backgroundColor: 'white' }}><Link to="/contact-us">Let's collaborate</Link></button>
                </div>
                <div className="ellipse_bottom"></div>
            </section>
            <Footer />
        </div>
    );
};

export default SocialNetworking;