import React from "react";
import Navbar from '../../components/Navbar';
import Footer from '../../components/Footer';
import BreadCrumb from '../../components/BreadCrumb';

const YouTubeMarketing = () => {
    return (
        <div>
            <Navbar />
                <div class="container">
                    <BreadCrumb 
                        active_page="YouTube Marketing" 
                        prev_sub_page={{
                            'link':'/services',
                            'text':'Services'
                        }}
                        sub_page={{
                            'link':'/services/digital-marketing',
                            'text':'Digital Marketing'
                        }} 
                    />
                    <div class="inner-container">
                        <h2>YouTube Marketing</h2>
                        <h3>Fuel Growth Of Business</h3>
                        <p>Get your message across through videos by engaging audience by telling a story. Add a tinge of entertainment to your marketing through product demonstrations, testimonials, interviews, case studies, & so on.</p>
                        <h3>Give Wings To Your Brand</h3>
                        <p>We have a dedicated team of YouTube marketing experts who would work on driving more views to your videos through our marketing services.</p>
                        <h3>Place Your Business Above Competitors</h3>
                        <p>Who knows exactly what it takes to make your brand reach new heights better than a Krisantician. We can be your way to reach a wider audience.</p>
                        <ul>
                            <li>Create Customized Channel</li>
                            <li>Integrate With Google Analytics</li>
                            <li>Content Targeting</li>
                            <li>YouTube SEO</li>
                            <li>Video Syndication</li>
                        </ul>
                        <h3>Our Ad Management Services</h3>
                        <ul>
                            <li>Pre Roll Video Ads</li>
                            <li>In Stream Ads</li>
                            <li>Bumper Ads</li>
                            <li>In Display Ads</li>
                            <li>Overlay Ads</li>
                        </ul>
                        <h3>Build Stronger Ties</h3>
                        <p>Users connect better with visuals rather than textual content. Incorporate our curated videos into your business campaigns. This mode is possibly the most effective way to promote your business.</p>
                        <h3>Why Choose Us</h3>
                        <ul>
                            <li>Views</li>
                            <li>Engagement</li>
                            <li>Branding</li>
                            <li>Subscribers</li>
                            <li>Social Trust</li>
                            <li>Monetization</li>
                        </ul>
                    </div>
                </div>
            <Footer />
        </div>
    );
};

export default YouTubeMarketing;