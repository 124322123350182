// GlobalContext.js
import React, { createContext, useContext, useState } from 'react';

const GlobalContext = createContext();

export const useGlobalContext = () => {
    return useContext(GlobalContext);
};

export const GlobalProvider = ({ children }) => {
    const [ipAddress, setIpAddress] = useState('');
    const [browserInfo, setBrowserInfo] = useState('');

    return (
        <GlobalContext.Provider value={{ ipAddress, setIpAddress, browserInfo, setBrowserInfo }}>
            {children}
        </GlobalContext.Provider>
    );
};
