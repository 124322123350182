import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import ReactGA from 'react-ga4';

const Footer = () => {
    let navigate = useNavigate()
    const sharedState = {
        sub_page: true,
        title: "IT Development & Consulting",
        url: "/services/it-development-consulting"
    };
    const navigation = (item) => {
        ReactGA.event({
            category: 'Footer',
            action: 'Click on Footer Link',
            label: item,
        })
        navigate(item, { state: sharedState });
    }
    return (
        <>
            <section class="footer">
                <div class="container">
                    <div class="row">
                        <div class="col-md-6">
                            <img alt="krisantec logo" src={require('../assets/images/logo3.webp')} width="200" />
                            {/* <p>Phone: +91 8008449532</p>
                            <p>Email: dr.divya@krisantec.com</p> */}
                            {/* <h3>KRISANTEC SOLUTIONS PVT LTD</h3> */}
                        </div>
                        <div class="col-md-6">
                            <div class='row'>
                                <div class='col-md-6'>
                                </div>
                                <div class='col-md-6 icons-follow'>
                                    <h4>Follow us</h4>
                                    <div class='d-flex' style={{ gap: '10px' }}>
                                        <a href="https://www.facebook.com/profile.php?id=100089380740551" target="_blank" style={{ color: '#ffffff', textDecoration: 'none' }}>
                                            <img alt="krisantec img" src={require('../assets/images/facebook.webp')} width="100%" class='social-icons' />
                                        </a>
                                        <a href="https://www.linkedin.com/company/krisantec-solutions/" target="_blank" style={{ color: '#ffffff', textDecoration: 'none' }}>
                                            <img alt="krisantec img" src={require('../assets/images/linkedin.webp')} width="100%" class='social-icons' />
                                        </a>
                                        <a href="https://www.instagram.com/krisantec_solutions/" target="_blank" style={{ color: '#ffffff', textDecoration: 'none' }}>
                                            <img alt="krisantec img" src={require('../assets/images/instagram.webp')} width="100%" class='social-icons' />
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* <hr /> */}
                    <div className="footer-bottom">
                        <div class="row">
                            <div class="col-md-4">
                                <h4 class='detail-list'>IT Development</h4>
                                <ul>
                                    <li><div onClick={() => navigation("/services/it-development-consulting/application-development")}>Application Development</div></li>
                                    <li><div onClick={() => navigation("/services/it-development-consulting/web-design-development")}>Web Design & Development</div></li>
                                    <li><div onClick={() => navigation("/services/it-development-consulting/erp-development")}>ERP Development</div></li>
                                    <li><div onClick={() => navigation("/services/it-development-consulting/product-development")}>Product Development</div></li>
                                    <li><div onClick={() => navigation("/services/it-development-consulting/product-maintenance")}>Product Maintenance</div></li>
                                    <li><div onClick={() => navigation("/services/it-development-consulting/it-consulting")}>IT Consulting</div></li>
                                </ul>
                                <h4 class='detail-list'>Business Domains</h4>
                                <ul>
                                    <li><Link to="/services/business-domains/healthcare-it">Healthcare IT</Link></li>
                                    <li><Link to="/services/business-domains/information-technology">Information Technology</Link></li>
                                    <li><Link to="/services/business-domains/e-commerce">E Commerce</Link></li>
                                    <li><Link to="/services/business-domains/elearning">eLearning</Link></li>
                                    <li><Link to="/services/business-domains/retail-software-system">Retail Software System</Link></li>
                                </ul>
                            </div>
                            {/* <div class="col-md-3">
                            </div> */}
                            <div class="col-md-4">
                                <h4 class='detail-list'>Digital Marketing</h4>
                                <ul>
                                    <li><Link to="/services/digital-marketing/search-engine-optimization">Search Engine Optimization</Link></li>
                                    <li><Link to="/services/digital-marketing/social-media-marketing">Social Media Marketing</Link></li>
                                    <li><Link to="/services/digital-marketing/search-engine-marketing">Search Engine Marketing</Link></li>
                                    <li><Link to="/services/digital-marketing/ppc-marketing">PPC Marketing</Link></li>
                                    <li><Link to="/services/digital-marketing/youtube-marketing">YouTube Marketing</Link></li>
                                    <li><Link to="/services/digital-marketing/email-marketing">Email Marketing</Link></li>
                                </ul>
                                <h4 class='detail-list'>Useful Links</h4>
                                <ul>
                                    <li>
                                        <Link to="/">Home</Link>
                                    </li>
                                    <li><Link to="/about">About</Link></li>
                                    <li><Link to="/contact-us">Contact</Link></li>
                                    <li><Link to="/blog">Blog</Link></li>
                                </ul>
                            </div>
                            <div class="col-md-4">
                                <h4 class='detail-list'>Business Process</h4>
                                <ul>
                                    <li><Link to="/services/business-process/erp-automation">ERP Automation</Link></li>
                                    <li><Link to="/services/business-process/hospital-information-system">Hospital Information System</Link></li>
                                    <li><Link to="/services/business-process/human-resource-management-information-system">Human Resource Management Information System</Link></li>
                                    <li><Link to="/services/business-process/sales-process-automation">Sales Process Automation</Link></li>
                                    <li><Link to="/services/business-process/billing-accounts-process-automation">Billing & Accounts Process Automation</Link></li>
                                    <li><Link to="/services/business-process/shopping-cart">Shopping Cart</Link></li>
                                    <li><Link to="/services/business-process/supply-chain-process">Supply Chain Process</Link></li>
                                    <li><Link to="/services/business-process/job-portal">Job Portal</Link></li>
                                    <li><Link to="/services/business-process/location-based-services">Location Based Services</Link></li>
                                    <li><Link to="/services/business-process/event-management-webinars">Event Management & Webinars</Link></li>
                                    <li><Link to="/services/business-process/social-networking">Social Networking</Link></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <p class="copyright">© Copyright | KRISANTEC SOLUTIONS PVT LTD | All Rights Reserved</p>
                </div>
            </section >
        </>
    );
};

export default Footer;