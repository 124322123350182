import React from "react";
import Navbar from '../../components/Navbar';
import Footer from '../../components/Footer';
import BreadCrumb from '../../components/BreadCrumb';

const PPCMarketing = () => {
    return (
        <div>
            <Navbar />
                <div class="container">
                    <BreadCrumb 
                        active_page="PPC Marketing" 
                        prev_sub_page={{
                            'link':'/services',
                            'text':'Services'
                        }}
                        sub_page={{
                            'link':'/services/digital-marketing',
                            'text':'Digital Marketing'
                        }} 
                    />
                    <div class="inner-container">
                        <h2>PPC Marketing</h2>
                        <h3>Widen your reach & exposure</h3>
                        <p>While SEO offers long term results, you might sometimes need an immediate boost to drive in traffic and increase sales.</p>
                        <p>Position your brand quickly and increase your search engine visibility with PPC campaigns</p>
                        <h3>Promote It Right</h3>
                        <p>Build your presence, promote, and gain immediate traction from specific audience segments. You can work closely with your dedicated Krisantician and choose the type of PPC ads you would prefer to fill the sales funnel.</p>
                        <h3>Ad Suited For Your Goal</h3>
                        <h3>Search Ads</h3>
                        <p>Targeted towards users who are looking out for services or offerings similar to yours. Best suited for short sales cycles and one time campaign promotions.</p>
                        <h3>Social Ads</h3>
                        <p>We reach prospects based on their hobbies, interests, network, & so on. This however requires an active social presence across all social media platforms.</p>
                        <h3>Remarketing Ads</h3>
                        <p>This works best for users who have already viewed your services and is targeted for conversion by eliciting brand offerings. We use smart PPC ads to incur significant results.</p>
                        <h3>Google Ads</h3>
                        <p>Suitable for business with large volume of products. You can choose this mode if you are aiming for users with a clear intent for buy.</p>
                        <h3>In Stream Ads</h3>
                        <p>These ads reflect on the you tube search results. We would elicit your brand in the form of story and present it in a unique way.</p>
                        <h3>Why PPC?</h3>
                        <ul>
                            <li>Granular Targeting</li>
                            <li>Quick Results</li>
                            <li>Expansive Traction Channels</li>
                            <li>Trackable Data</li>
                        </ul>
                    </div>
                </div>
            <Footer />
        </div>
    );
};

export default PPCMarketing;