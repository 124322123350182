import React,{ useEffect } from 'react';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/js/dist/collapse';
import 'bootstrap/js/dist/dropdown';
import { GlobalProvider } from './components/GlobalProvider';

import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';
import Home from "./pages/Home";
import About from "./pages/About";
import ContactUs from "./pages/ContactUs";
import Blog from './pages/Blog/Blog';
import BlogDetail from './pages/Blog/BlogDetail';
import ItDevelopment from "./pages/ItDevelopment/ItDevelopment";
import ApplicationDevelopment from "./pages/ItDevelopment/ApplicationDevelopment";
import WebDesignDevelopment from "./pages/ItDevelopment/WebDesignDevelopment";
import ERPDevelopment from "./pages/ItDevelopment/ERPDevelopment";
import ProductDevelopment from "./pages/ItDevelopment/ProductDevelopment";
import ProjectMaintenance from "./pages/ItDevelopment/ProjectMaintenance";
import ITConsulting from "./pages/ItDevelopment/ITConsulting";
import DigitalMarketing from "./pages/DigitalMarketing/DigitalMarketing";
import SearchEngineOptimization from "./pages/DigitalMarketing/SearchEngineOptimization";
import SocialMediaMarketing from "./pages/DigitalMarketing/SocialMediaMarketing";
import SearchEngineMarketing from "./pages/DigitalMarketing/SearchEngineMarketing";
import PPCMarketing from "./pages/DigitalMarketing/PPCMarketing";
import YouTubeMarketing from "./pages/DigitalMarketing/YouTubeMarketing";
import EmailMarketing from "./pages/DigitalMarketing/EmailMarketing";
import BusinessDomains from "./pages/BusinessDomains/BusinessDomains";
import HealthcareIT from "./pages/BusinessDomains/HealthcareIT";
import InformationTechnology from "./pages/BusinessDomains/InformationTechnology";
import ECommerce from "./pages/BusinessDomains/ECommerce";
import ELearning from "./pages/BusinessDomains/ELearning";
import RetailSoftwareSystem from "./pages/BusinessDomains/RetailSoftwareSystem";
import BusinessProcess from "./pages/BusinessProcess/BusinessProcess";
import ERPAutomation from "./pages/BusinessProcess/ERPAutomation";
import HospitalInformationSystem from "./pages/BusinessProcess/HospitalInformationSystem";
import HumanResourceManagementInformationSystem from "./pages/BusinessProcess/HumanResourceManagementInformationSystem";
import SalesProcessAutomation from "./pages/BusinessProcess/SalesProcessAutomation";
import BillingAccountsProcessAutomation from "./pages/BusinessProcess/BillingAccountsProcessAutomation";
import ShoppingCart from "./pages/BusinessProcess/ShoppingCart";
import SupplyChainProcess from "./pages/BusinessProcess/SupplyChainProcess";
import JobPortal from "./pages/BusinessProcess/JobPortal";
import LocationBasedServices from "./pages/BusinessProcess/LocationBasedServices";
import EventManagementWebinars from "./pages/BusinessProcess/EventManagementWebinars";
import SocialNetworking from "./pages/BusinessProcess/SocialNetworking";
import ScrollToTop from './components/ScrollToTop';
import Services from './pages/Services';
import { FriendlyUrlComponent } from './components/FriendlyComponent/FriendlyComponent';
import CookieBanner from './pages/CookieBanner';
import Navbar from './components/Navbar';
import ReactGA from "react-ga4";

const TRACKING_ID = "G-YZBWXDW203";
ReactGA.initialize(TRACKING_ID);



function App() {
  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: window.location.pathname, title: document.title });
  }, []);
    return (
        <GlobalProvider> {/* Wrap your application with GlobalProvider */}
            <Router>
                <ScrollToTop />
                <CookieBanner />
                <Routes>
                    <Route path="/" element={<Home />} />
                    <Route path="/about" element={<About />} />
                    <Route path="/navbar" element={<Navbar />} />
                    <Route path="/contact-us" element={<ContactUs />} />
                    <Route path="/blog" element={<Blog />} />
                    <Route path="/blog/:seo_url/" element={<FriendlyUrlComponent />} />
                    <Route path="/blog/:slug/:seo_url/" element={<FriendlyUrlComponent />} />
                    <Route path="/services" element={<Services />} />
                    <Route path="/services/it-development-consulting" element={<ItDevelopment />} />
                    <Route path="/services/it-development-consulting/application-development" element={<ApplicationDevelopment />} />
                    <Route path="/services/it-development-consulting/web-design-development" element={<WebDesignDevelopment />} />
                    <Route path="/services/it-development-consulting/erp-development" element={<ERPDevelopment />} />
                    <Route path="/services/it-development-consulting/product-development" element={<ProductDevelopment />} />
                    <Route path="/services/it-development-consulting/product-maintenance" element={<ProjectMaintenance />} />
                    <Route path="/services/it-development-consulting/it-consulting" element={<ITConsulting />} />
                    <Route path="/services/digital-marketing" element={<DigitalMarketing />} />
                    <Route path="/services/digital-marketing/search-engine-optimization" element={<SearchEngineOptimization />} />
                    <Route path="/services/digital-marketing/social-media-marketing" element={<SocialMediaMarketing />} />
                    <Route path="/services/digital-marketing/search-engine-marketing" element={<SearchEngineMarketing />} />
                    <Route path="/services/digital-marketing/ppc-marketing" element={<PPCMarketing />} />
                    <Route path="/services/digital-marketing/youtube-marketing" element={<YouTubeMarketing />} />
                    <Route path="/services/digital-marketing/email-marketing" element={<EmailMarketing />} />
                    <Route path="/services/business-domains" element={<BusinessDomains />} />
                    <Route path="/services/business-domains/healthcare-it" element={<HealthcareIT />} />
                    <Route path="/services/business-domains/information-technology" element={<InformationTechnology />} />
                    <Route path="/services/business-domains/e-commerce" element={<ECommerce />} />
                    <Route path="/services/business-domains/elearning" element={<ELearning />} />
                    <Route path="/services/business-domains/retail-software-system" element={<RetailSoftwareSystem />} />
                    <Route path="/services/business-process" element={<BusinessProcess />} />
                    <Route path="/services/business-process/erp-automation" element={<ERPAutomation />} />
                    <Route path="/services/business-process/hospital-information-system" element={<HospitalInformationSystem />} />
                    <Route path="/services/business-process/human-resource-management-information-system" element={<HumanResourceManagementInformationSystem />} />
                    <Route path="/services/business-process/sales-process-automation" element={<SalesProcessAutomation />} />
                    <Route path="/services/business-process/billing-accounts-process-automation" element={<BillingAccountsProcessAutomation />} />
                    <Route path="/services/business-process/shopping-cart" element={<ShoppingCart />} />
                    <Route path="/services/business-process/supply-chain-process" element={<SupplyChainProcess />} />
                    <Route path="/services/business-process/job-portal" element={<JobPortal />} />
                    <Route path="/services/business-process/location-based-services" element={<LocationBasedServices />} />
                    <Route path="/services/business-process/event-management-webinars" element={<EventManagementWebinars />} />
                    <Route path="/services/business-process/social-networking" element={<SocialNetworking />} />
                </Routes>
            </Router>
        </GlobalProvider>
    );
  }

export default App;
