import React from "react";
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import BreadCrumb from '../components/BreadCrumb';
import { Link } from 'react-router-dom';

const Services = () => {
    return (
        <div>
            <Navbar />
            <div class="container">
                <BreadCrumb active_page="Services" />
                <div class="inner-container">
                    <h2>Services</h2>
                    <div className="services-grid">
                        <div className="row">
                            <div className="col-md-6">
                                <Link to="/services/it-development-consulting">
                                    <div className="service-container">
                                        <img alt="IT-development" src={require('../assets/images/services/ondemand.webp')} width="100%" class="hire-image" />
                                        <h3>IT Development & Consulting</h3>
                                    </div>
                                </Link>
                            </div>
                            <div className="col-md-6">
                                <Link to="/services/digital-marketing">
                                    <div className="service-container">
                                        <img alt="IT-development" src={require('../assets/images/services/social.webp')} width="100%" class="hire-image" />
                                        <h3>Digital Marketing</h3>
                                    </div>
                                </Link>
                            </div>
                            <div className="col-md-6">
                                <Link to="/services/business-domains">
                                    <div className="service-container">
                                        <img alt="IT-development" src={require('../assets/images/services/healthcare.webp')} width="100%" class="hire-image" />
                                        <h3>Business Domains</h3>
                                    </div>
                                </Link>
                            </div>
                            <div className="col-md-6">
                                <Link to="/services/business-process">
                                    <div className="service-container">
                                        <img alt="IT-development" src={require('../assets/images/services/it.webp')} width="100%" class="hire-image" />
                                        <h3>Business Process</h3>
                                    </div>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <section className="newproject">
                <div className="ellipse_top" style={{ backgroundColor: 'white' }}></div>
                <div class="container">
                    <p>Let's Work Together</p>
                    <h3>Turn your vision<br /> into reality</h3>
                    <button className="btn btn-default" style={{ backgroundColor: 'white' }}><Link to="/contact-us">Let's collaborate</Link></button>
                </div>
                <div className="ellipse_bottom"></div>
            </section>
            <Footer />
        </div>
    );
};

export default Services;