import React, { useState } from 'react';
import axiosConfig from "../Service/axiosConfig";

const ContactForm = () => {
  const [formData, setFormData] = useState({
    name: '',
    mobile: '',
    email: '',
    query: ''
  });
const[Success,setSucess]=useState("")
  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const formData = new FormData(event.target);
    const formObject = {};
    formData.forEach((value, key) => {
      formObject[key] = value;
    });
    formObject.service = "Customer Support";
    formObject.source = "Customer Support Page";
    try {
      const response = await axiosConfig.post(`/blogapp/leads/`, formObject)
      setSucess("Your query has been submitted")
      setFormData({ name: '', mobile: '', email: '', query: '' });
      event.target.reset();
    } catch (error) {
      console.log("error submitting the query : ", error)
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <div className="mb-3 mt-3">
        <label htmlFor="name">Name:</label>
        <input type="text" className="form-control" id="name" placeholder="Enter Name" name="name" value={formData.name} onChange={handleChange} />
      </div>
      <div className="mb-3 mt-3">
        <label htmlFor="contact">Contact No:</label>
        <input type="text" className="form-control" id="mobile" placeholder="Enter Contact No" name="mobile" value={formData.contact} onChange={handleChange} />
      </div>
      <div className="mb-3 mt-3">
        <label htmlFor="email">Email:</label>
        <input type="email" className="form-control" id="email" placeholder="Enter Email" name="email" value={formData.email} onChange={handleChange} />
      </div>
      <div className="mb-3 mt-3">
        <label htmlFor="query">Query:</label>
        <textarea className="form-control" rows="2" id="query" name="query" value={formData.query} onChange={handleChange}></textarea>
      </div>
      {Success && (
        <p style={{ color: 'green', fontWeight: 'bold' }}>
          {Success}
        </p>
      )}
      <button type="submit" className="btn btn-primary">Submit</button>
    </form>
  );
};

export default ContactForm;
