import React, { useRef, useState, useEffect } from "react";
import Navbar from '../../components/Navbar';
import Footer from '../../components/Footer';
import BreadCrumb from '../../components/BreadCrumb';
import { Link } from 'react-router-dom';
import { FaCheckCircle } from "react-icons/fa";
import Slider from "react-slick";
import { GoArrowUpRight, GoArrowDownLeft } from "react-icons/go";
import { useNavigate } from "react-router-dom";

const DigitalMarketing = () => {
    let sliderRef = useRef(null);
    const navigate = useNavigate();
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const handleClick = (url) => {
        navigate(url);
    }
    var settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: windowWidth > 991 ? 2 : 1,
        slidesToScroll: 1,
        adaptiveHeight: true,
        // autoplay: true,
        appendDots: dots => (
            <div
                style={{
                    position: "relative"
                }}
            >
                <div style={{ position: "absolute" }}>
                    <ul style={{ margin: "0px" }} className="dm-dots"> {dots} </ul>
                </div>
            </div>
        )
    };

    const next = () => {
        sliderRef.slickNext();
    };
    const previous = () => {
        sliderRef.slickPrev();
    };

    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };

        window.addEventListener('resize', handleResize);

        handleResize();

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
        <div>
            <Navbar />
            <section className="about-breadcrumb">
                <BreadCrumb
                    active_page="Digital Marketing"
                    prev_sub_page={{
                        'link': '/services',
                        'text': 'Services'
                    }}
                />
            </section>
            <section className="dm-top">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6">
                            <div className="d-flex" style={{ gap: "16px" }}>
                                <FaCheckCircle size={20} color="#5020D9" style={{ flexShrink: 0, marginTop: "5px" }} />
                                <div>
                                    <h3>Let’s Strategise Together</h3>
                                    <p>We work with you, not for you. Be it content, designing or strategies, we take
                                        care of all of it.</p>
                                </div>
                            </div>
                            <div className="d-flex" style={{ gap: "16px" }}>
                                <FaCheckCircle size={20} color="#36CFEB" style={{ flexShrink: 0, marginTop: "5px" }} />
                                <div>
                                    <h3>Experience Led & Outcome Focused</h3>
                                    <p>Best recipes are made using the right proportion of ingredients, same goes
                                        with digital marketing. For a comprehensive outreach we ensure all the
                                        campaigns are aligned across various platforms.</p>
                                </div>
                            </div>
                            <div className="d-flex" style={{ gap: "16px" }}>
                                <FaCheckCircle size={20} color="#FF6433" style={{ flexShrink: 0, marginTop: "5px" }} />
                                <div>
                                    <h3>Agile SEO Methodology</h3>
                                    <p>Involves building customized SEO strategies well in line with your business
                                        needs, demands, and targeted users.</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6"></div>
                    </div>
                </div>
            </section>
            <section className="dm-slider">
                <div className="container">
                    <img src={require('../../assets/images/services/dmslide.png')} />
                    <Slider
                        ref={slider => {
                            sliderRef = slider;
                        }}
                        {...settings}
                    >
                        <div className="dm-card">
                            <div>
                                <h5>Discover</h5>
                                <p>Understand business needs and demands after an initial review. This is followed by identifying the key performance indicators, conversion path, and keyword research. This gives us insights on company processes, purpose, and goals.</p>
                            </div>
                        </div>
                        <div className="dm-card">
                            <div>
                                <h5>Analyze</h5>
                                <p>Involves site audit, review of site analytics, and traffic patterns. On site technical issues if any are also identified at this stage. Competitor analysis forms a large part of our strategy and we utilise their analytics to further refine our approaches for SEO.</p>
                            </div>
                        </div>
                        <div className="dm-card">
                            <div>
                                <h5>Strategize</h5>
                                <p>This includes planning of the expected for all kinds of marketing channels alon estimated time. This ensures our effort line with immediate targeted results.</p>
                            </div>
                        </div>
                    </Slider>
                    <div className="dm-btn-parent">
                        <button className="btn" onClick={previous}>
                            <GoArrowDownLeft color="#2362B6" />
                        </button>
                        <button className="btn" onClick={next}>
                            <GoArrowUpRight color="#2362B6" />
                        </button>
                    </div>
                </div>
            </section>
            <section className="dm-we-do">
                <div className="container">
                    <h2>What We Do</h2>
                    <div className="grid_3">
                        <div className="card" onClick={() => handleClick('/services/digital-marketing/search-engine-optimization')}>
                            <img src={require('../../assets/images/services/seo.webp')} />
                            <h5>Search Engine Optimization</h5>
                            <p>Involves auditing your site, keyword research, on page optimization, off page optimization, local seo, technical seo and activity reports.</p>
                        </div>
                        <div className="card" >
                            <img src={require('../../assets/images/services/sm.webp')} onClick={() => handleClick('/services/digital-marketing/social-media-marketing')}/>
                            <h5>Social Media Marketing</h5>
                            <p>Leverage the power of social media networks to achieve branding goals. Stay connected with your audience and build a community around your brand</p>
                        </div>
                        <div className="card" >
                            <img src={require('../../assets/images/services/sem.webp')} onClick={() => handleClick('/services/digital-marketing/search-engine-marketing')}/>
                            <h5>Search Engine Marketing</h5>
                            <p>Improve your digital presence and secure your brand recognition. We ensure search engines find your site and make it rank high in search engine page results.</p>
                        </div>
                        <div className="card" >
                            <img src={require('../../assets/images/services/pm.webp')} onClick={() => handleClick('/services/digital-marketing/ppc-marketing')}/>
                            <h5>PPC Marketing</h5>
                            <p>Give your brand a wider reach & exposure. While SEO offers long term results, you might sometimes need an immediate boost to drive in traffic and increase sales.</p>
                        </div>
                        <div className="card" >
                            <img src={require('../../assets/images/services/ym.webp')} onClick={() => handleClick('/services/digital-marketing/youtube-marketing')}/>
                            <h5>YouTube Marketing</h5>
                            <p>Get your message across through videos by engaging audience by telling a story. Add a tinge of entertainment to your marketing th- -rough product demonstrations, testimonials, interviews, case studies, & so on.</p>
                        </div>
                        <div className="card" >
                            <img src={require('../../assets/images/services/em.webp')} onClick={() => handleClick('/services/digital-marketing/email-marketing')}/>
                            <h5>Email Marketing</h5>
                            <p>Cement your brand identity. We help you plan, build, execute all kinds of email marketing campaigns i.e., newsletters, product, promotional, and transactional.</p>
                        </div>
                    </div>
                </div>
            </section>
            <section className="newproject">
                <div className="ellipse_top" style={{ backgroundColor: 'white' }}></div>
                <div class="container">
                    <p>Let's Work Together</p>
                    <h3>Turn your vision<br /> into reality</h3>
                    <button className="btn btn-default" style={{ backgroundColor: 'white' }}><Link to="/contact-us">Let's collaborate</Link></button>
                </div>
                <div className="ellipse_bottom"></div>
            </section>
            <Footer />
        </div>
    );
};

export default DigitalMarketing;