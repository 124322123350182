import React, { useState, useEffect } from "react";
// import "./FriendlyUrlComponent.css"
import axiosConfig from "../../Service/axiosConfig"
import { NavLink, useParams } from "react-router-dom";
import Navbar from "../Navbar";
import Footer from "../Footer";
import Blog from "../../pages/Blog/Blog";
import BlogDetail from "../../pages/Blog/BlogDetail";

const FriendlyUrlComponent = () => {

    let { slug, seo_url } = useParams();
    console.log(seo_url)
    console.log(slug)

    const [loading, setLoading] = useState(false)
    const [ProductObject, setProductObject] = useState({
        "product_type": "",
        "product_data": "",
    })
    const [Data, SetData] = useState([])
    const GetData = async () => {
        setLoading(true)
        try {
            const response = await axiosConfig.get(`/catlog/seo-url/${seo_url}/`)
            console.log(response)
            setProductObject({ "product_type": response.data.product_type, "product_data": response.data.product_data })
            setLoading(false)
        }
        catch (error) {
            console.log(error)
            setLoading(false)
        }
    }
    useEffect(() => {
        GetData();
    }, [seo_url])

    return (
        <>
        <Navbar/>
           <div className="categories-container">
                {loading ? (
                    <div className="loader-container">
                        loader....
                    </div>
                ) : (
                    <div className="">
                    {ProductObject.product_type == "blogcategory" &&
                        <Blog category={ProductObject.product_data.id}  />
                    }
                     {ProductObject.product_type == "blog" &&
                        <BlogDetail item={ProductObject.product_data}  />
                    }
                    </div>
                )}
            </div>
            <Footer/>

        </>
    )
}
export { FriendlyUrlComponent };