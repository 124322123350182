import React from "react";
import Navbar from '../../components/Navbar';
import Footer from '../../components/Footer';
import BreadCrumb from '../../components/BreadCrumb';

const EventManagementWebinars = () => {
    return (
        <div>
            <Navbar />
                <div class="container">
                    <BreadCrumb 
                        active_page="Event Management & Webinars" 
                        prev_sub_page={{
                            'link':'/services',
                            'text':'Services'
                        }}
                        sub_page={{
                            'link':'/services/business-process',
                            'text':'Business Process'
                        }} 
                    />
                    <div class="inner-container">
                        <h2>Event Management & Webinars</h2>
                        <h3>Choose Right Platform</h3>
                        <p>Our solutions deliver not just a virtual learning experience but a real interaction among learners. You can facilitate discussions using our virtual tools like whiteboard, breakout rooms, chatbox, polling, Q & A session.</p>
                        <h3>Elicit Your Story Your Way</h3>
                        <p>You can design you story board design and deliver customizable experiences suited to your audience.</p>
                        <h3>Extend Your Reach</h3>
                        <p>Our HTML browser would help you reach a wider audience with accessible design and frictionless access</p>
                        <h3>Leveraging Technology For Impact</h3>
                        <p>For a large scale virtual events we also offer campaign management from promotions, registrations, integration with CRM, & so on</p>
                        <h3>How We Do</h3>
                        <img alt="" src={require('../../assets/images/services/emw.png')} className="d-block m-auto mw-50" />
                        <h3>Make Them Happen All In Real Time</h3>
                        <ul>
                            <li>Tutoring</li>
                            <li>Training Workforce</li>
                            <li>Virtual Offices</li>
                            <li>Live Help Desk</li>
                            <li>Emergency Preparedness & Rapid Response</li>
                        </ul>
                        <h3>Our Unique Features</h3>
                        <ul>
                            <li>Concatenation</li>
                            <li>Scheduling</li>
                            <li>Breakout Rooms</li>
                            <li>Secured Invites</li>
                            <li>Polls & Surveys</li>
                            <li>Integrated E Commerce</li>
                        </ul>
                        <h3>Why Choose Us</h3>
                        <ul>
                            <li>Interactive Features</li>
                            <li>Timed Testing Options</li>
                            <li>Storage Options</li>
                            <li>Data Security</li>
                        </ul>
                    </div>
                </div>
            <Footer />
        </div>
    );
};

export default EventManagementWebinars;