import React from "react";
import Navbar from '../../components/Navbar';
import Footer from '../../components/Footer';
import BreadCrumb from '../../components/BreadCrumb';
import { Link } from "react-router-dom";
const ELearning = () => {
    return (
        <div>
            <Navbar />
            <section className="about-breadcrumb">
                <BreadCrumb
                    active_page="eLearning"
                    prev_sub_page={{
                        'link': '/services',
                        'text': 'Services'
                    }}
                    sub_page={{
                        'link': '/services/business-domains',
                        'text': 'Business Domains'
                    }}
                />
            </section>
            <section className="you-name">
                <div className="container">
                    <div className="row">
                        {/* <div className="col-md-6 mobile">
                            <img src={require("../../assets/images/services/you_name.webp")} alt="right sized" className="img-fluid" />
                        </div> */}
                        <div className="col-md-6">
                            <h3>You Name It, We Provide It</h3>
                            <ul>
                                <li>Solutions should not only solve problems but add value to the environment in which the solution is offered.</li>
                                <li>We just don’t create eLearning tools, but offer you an incredible learning experience. Our eLearning solutions involves a classroom blended virtual delivery mode of approach</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </section>
            <section class="banner-text">
                <div class="container">
                    <h4>How Do We Do</h4>
                    <p>We blend together instructional design strategies and visual principles to create customized e Learning solutions.</p>
                </div>
            </section>
            <section className="technology">
                <div className="container">
                    <h2>Technology At Its Best</h2>
                    <h3>LMS</h3>
                    <p>Ease your administration activities and improve learning outcomes through learning management system. Our range of learning methodologies will ensure no child is left behind in learning.</p>
                    <div className="grid_container_3">
                        <div className="grid-item">
                            <div>Academic schedules</div>
                        </div>
                        <div className="grid-item">
                            <div>Learning resources</div>
                        </div>
                        <div className="grid-item">
                            <div>Remote learning</div>
                        </div>
                    </div>
                    <div className="grid_container_2">
                        <div className="grid-item">
                            <div>Assessment</div>
                        </div>
                        <div className="grid-item">
                            <div>Reports</div>
                        </div>
                    </div>
                </div>
            </section>
            <section class="banner-text exam">
                <div class="container">
                    <h2>Exam Assessment Tool</h2>
                    <p>You can create your online tests automatically and assess performance of your students</p>
                    <div className="grid_container_3">
                        <div className="grid-item">Automated Tests</div>
                        <div className="grid-item">User Management</div>
                        <div className="grid-item">Craft Questions</div>
                    </div>
                </div>
            </section>
            <section className="virtual">
                <div className="container">
                    <h2>Virtual Classrooms</h2>
                    <p>It’s never been easier to teach online. We help you digitize the classroom environment and enhance learning experiences</p>
                    <div className="row">
                        <div className="col-md-6">
                            <ul className="blue-arrows">
                                <li>Integrated scheduler</li>
                                <li>Recorded Sessions</li>
                                <li>Reports</li>
                                <li>Notifications</li>
                            </ul>
                        </div>
                        <div className="col-md-6">
                            <img src={require('../../assets/images/services/virtual.webp')} alt="virtual classrooms" className="img-fluid" />
                        </div>
                    </div>
                </div>
            </section>
            <section className="benefits education">
                <div className="container">
                    <h2>Education Management Software</h2>
                    <p>Convenient cloud based solution to manage school administration activities in a comprehensive way</p>
                    <div className="grid_container_3">
                        <div className="grid-item">
                            Paperless Technology
                        </div>
                        <div className="grid-item">
                            Actionable Insights
                        </div>
                        <div className="grid-item">
                            RFID Readers
                        </div>
                    </div>
                    <div className="grid_container_2">
                        <div className="grid-item">
                            Real Time GPS Access
                        </div>
                        <div className="grid-item">
                            Online Fee Payments
                        </div>
                    </div>
                </div>
            </section>
            <section className="newproject">
                <div className="ellipse_top"></div>
                <div class="container">
                    <p>Let's Work Together</p>
                    <h3>Turn your vision<br /> into reality</h3>
                    <button className="btn btn-default" style={{ backgroundColor: 'white' }}><Link to="/contact-us">Let's collaborate</Link></button>
                </div>
                <div className="ellipse_bottom"></div>
            </section>
            <Footer />
        </div>
    );
};

export default ELearning;