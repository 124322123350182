import axios from 'axios';
import React, { useState, useEffect } from 'react';
import { useGlobalContext } from '../components/GlobalProvider';
const CookieConsent = () => {
  const [cookiesAccepted, setCookiesAccepted] = useState(false);
  const { setIpAddress, setBrowserInfo,ipAddress, browserInfo } = useGlobalContext();

  // Initialize counts
  const [acceptCount, setAcceptCount] = useState(0);
  const [rejectCount, setRejectCount] = useState(0);

  useEffect(() => {
    // Fetch the user's IP address
    axios.get("https://api.ipify.org/?format=json")
      .then(res => {
        const ip = res.data.ip;
        setIpAddress(ip);
        // Fetch additional geo data (optional)
        axios.get(`https://geo.ipify.org/api/v2/country?apiKey=at_SWxj9yTGSklXBsHbV1Cv9yzmFqkal&ipAddress=${ip}`)
          .then(response => {
            console.log("Geo Info: ", response.data);
          })
          .catch(error => {
            console.log("Geo Info Error: ", error);
          });
      })
      .catch(error => {
        console.log("IP Fetch Error: ", error);
      });

    // Detect browser from the user-agent string
    const userAgent = navigator.userAgent;

    let detectedBrowser = "Unknown Browser";

    if (userAgent.includes("Edg")) {
      detectedBrowser = "Edge";
    } else if (userAgent.includes("Chrome") && !userAgent.includes("Edg")) {
      detectedBrowser = "Chrome";
    } else if (userAgent.includes("Safari") && !userAgent.includes("Chrome")) {
      detectedBrowser = "Safari";
    }

    setBrowserInfo(detectedBrowser); // Store browser info in state
    const acceptedCount = localStorage.getItem('acceptCount') || 0;
    const rejectedCount = localStorage.getItem('rejectCount') || 0;
    setAcceptCount(Number(acceptedCount));
    setRejectCount(Number(rejectedCount));

  }, []);

  const acceptCookies = () => {
    // Set cookies for consent, browser info, and IP address
    document.cookie = "cookiesAccepted=true; path=/; max-age=" + 60 * 60 * 24 * 365; // Set for 1 year
    document.cookie = `browserInfo=${browserInfo}; path=/; max-age=` + 60 * 60 * 24 * 365;
    document.cookie = `ipAddress=${ipAddress}; path=/; max-age=` + 60 * 60 * 24 * 365;

    console.log("Cookies set: ", {
      cookiesAccepted: true,
      browserInfo: browserInfo,
      ipAddress: ipAddress,
    });
    
    console.log("Updated Cookies: ", document.cookie); // Log cookies after setting

    setCookiesAccepted(true); // Hide the banner after consent

    // Update acceptance count
    const newAcceptCount = acceptCount + 1;
    setAcceptCount(newAcceptCount);
    localStorage.setItem('acceptCount', newAcceptCount);
  };

  const rejectCookies = () => {
    setCookiesAccepted(true); // Hide the banner after rejecting

    // Update rejection count
    const newRejectCount = rejectCount + 1;
    setRejectCount(newRejectCount);
    localStorage.setItem('rejectCount', newRejectCount);
  };

  const getCookie = (name) => {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop().split(';').shift();
    return null;
  };

  useEffect(() => {
    const cookiesConsent = getCookie('cookiesAccepted');
    if (cookiesConsent) {
      setCookiesAccepted(true);
    }
  }, []);

  if (cookiesAccepted) return null; // Hide the banner if cookies are accepted or rejected

  return (
    <div className="cookie-banner" style={bannerStyle}>
    <p style={{ margin: 0 }}>
      This website uses cookies to enhance your experience.
    </p>
    <div className="cookie-actions" style={{ marginTop: '10px' }}>
      <button onClick={acceptCookies} className="btn btn-primary" style={buttonStyle}>
        Accept
      </button>
      <button onClick={rejectCookies} className="btn btn-primary" style={{ ...buttonStyle, marginLeft: '10px' }}>
        Reject
      </button>
    </div>
  </div>
  
  );
};

// Inline styles for the cookie banner and button
const bannerStyle = {
  position: 'fixed',
  bottom: 0,
  width: '100%',
  backgroundColor: '#222',
  color: 'white',
  padding: '15px',
  textAlign: 'center',
  zIndex: 1000,
};

const buttonStyle = {
  marginLeft: '10px',
  padding: '5px 10px',
  backgroundColor: '#4CAF50',
  color: 'white',
  border: 'none',
  cursor: 'pointer',
};

export default CookieConsent;
