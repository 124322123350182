import React, { useState, useRef, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import '../Style Guide/styles.css';
import { CiSearch } from "react-icons/ci";
import { BiMenuAltLeft } from "react-icons/bi";
import { ChatBot } from '../pages/ChatBot';

const Navbar = ({browserInfo ,ipAddress}) => {

    const [isSticky, setIsSticky] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY > 40) { // Adjust the scroll threshold as needed
                setIsSticky(true);
            } else {
                setIsSticky(false);
            }
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (
        <>
            <nav className={`navbar navbar-expand-lg bg-light custom-nav-bar navbar-light fixed-top`}>
                <div className="container">
                    <NavLink className="navbar-brand" to="/">
                        <img alt="krisantec logo" src={require('../assets/images/logo.png')} width="200" />
                    </NavLink>
                    {/* <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#collapsibleNavbar">
                        <span className="navbar-toggler-icon"></span>
                    </button> */}
                    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#collapsibleNavbar">
                        <BiMenuAltLeft className="navbar-toggler-icon" color='white' />
                    </button>

                    <div className="collapse navbar-collapse" id="collapsibleNavbar">
                        <ul className="navbar-nav ms-auto">
                            <li className="nav-item">
                                <NavLink className="nav-link jumplink" activeClassName="active" exact to="/">Home</NavLink>
                            </li>
                            <li className="nav-item">
                                <NavLink className="nav-link jumplink" activeClassName="active" to="/about">About</NavLink>
                            </li>
                            <li className="nav-item dropdown">
                                <NavLink className="nav-link dropdown-toggle" activeClassName="active" to="/services" role="button">Services</NavLink>
                                <ul className="dropdown-menu">
                                    <li>
                                        <NavLink className="dropdown-item" activeClassName="active" to="/services/it-development-consulting">IT Development & Consulting</NavLink>
                                        <NavLink className="dropdown-item" activeClassName="active" to="/services/digital-marketing">Digital Marketing</NavLink>
                                        <NavLink className="dropdown-item" activeClassName="active" to="/services/business-domains">Business Domains</NavLink>
                                        <NavLink className="dropdown-item" activeClassName="active" to="/services/business-process">Business Process</NavLink>
                                    </li>
                                </ul>
                            </li>
                            {/* <li className="nav-item">
                                <NavLink className="nav-link jumplink" activeClassName="active" to="/projects">Projects</NavLink>
                            </li> */}
                            <li className="nav-item">
                                <NavLink className="nav-link jumplink" activeClassName="active" to="/blog">Blog</NavLink>
                            </li>
                            <li className="nav-item">
                                <NavLink className="nav-link jumplink" activeClassName="active" to="/contact-us">Contact</NavLink>
                            </li>
                            {/* <li className="nav-item">
                                <NavLink className="nav-link jumplink" activeClassName="active" to="/search" style={{ margin: '0 20px 0px 45px' }}>
                                    <CiSearch size={24} color='black' />
                                </NavLink>
                            </li>
                            <li>
                                <button className='btn btn-secondary'>
                                    <NavLink to="/chat">Let's Talk</NavLink>
                                </button>
                            </li> */}
                        </ul>
                    </div>
                </div>
            </nav>
            <div className="chat-wrapper">
                <div className="whatsapp-contact">
                    <a href="https://api.whatsapp.com/send?phone=918008449532&text=Hi+I+want+to+know+more+about+your+services" target="_blank">
                        <img alt="krisantec img" src={require('../assets/images/whatsapp1.webp')} />
                    </a>
                </div>
                <ChatBot browserInfo={browserInfo} ipAddress={ipAddress} />
            </div>
            <div class="belownav"></div>
        </>
    );
};

export default Navbar;