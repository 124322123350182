import React, { useRef, useState, useEffect } from "react";
import './Home.css';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import { Link, useNavigate } from 'react-router-dom';
import Slider from "react-slick";
import MaterialIcon, { colorPalette } from 'material-icons-react';
import { Helmet } from "react-helmet";
import ContactForm from "../components/ContactForm";
import '../Style Guide/styles.css';
import { GoArrowLeft, GoArrowRight } from "react-icons/go";
import axiosConfig from "../Service/axiosConfig";

const Home = ({browserInfo,ipAddress}) => {
    let sliderRef = useRef(null);
    const navigate = useNavigate();
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [blogs, setBlogs] = useState([]);
    const handleBlogClick = (item) => {
         navigate(`/blog/${item?.category?.slug}`, { state: { category:item?.category?.id} });
    }
    const getBlogs = async () => {
        try {
            const blog_response = await axiosConfig.get(`/blogapp/blog/?page_size=2`)
            setBlogs(blog_response.data.results)
        } catch (error) {
            console.log("Error getting blogs : ", error)
        }
    }
    var settings = {
        dots: true,
        autoplay: true,
        autoplaySpeed: 2000,
        infinite: true,
        slidesToShow: 5,
        slidesToScroll: 5,
        responsive: [
            {
                breakpoint: 991,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    // centerMode: true
                }
            }
        ]
    };

    var settings2 = {
        // autoplay: true,
        autoplaySpeed: 2000,
        infinite: true,
        slidesToShow: windowWidth > 768 ? 2 : 1,
        slidesToScroll: 1,
        nextArrow: <SampleNextArrow />,
        prevArrow: <SamplePrevArrow />,
    };

    function SampleNextArrow(props) {
        const { className, style, onClick } = props;
        return (
            <div
                className={className}
                style={{ ...style, display: "none" }}
                onClick={onClick}
            />
        );
    }

    function SamplePrevArrow(props) {
        const { className, style, onClick } = props;
        return (
            <div
                className={className}
                style={{ ...style, display: "none" }}
                onClick={onClick}
            />
        );
    }

    const next = () => {
        sliderRef.slickNext();
    };
    const previous = () => {
        sliderRef.slickPrev();
    };
    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };

        window.addEventListener('resize', handleResize);

        handleResize();
        getBlogs();

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);
    return (
        <div>
            <Helmet>
                <title>Krisantec Solutions - IT Services | Consulting</title>
                <meta name="description" content="We incorporate innovative technology such as automation, AI to deliver application & solutions that drive business growth" />
                <meta name="keywords" content="Krisantec, Krisantec Solutions, Kristec,  Krisatec, Krisntec, IT Services, Consulting, it company, it company in hyderabad, technology company, software company in hyderabad, it companies near me, it solutions, technology solutions, software solutions" />
                <link rel="icon" href={require('../assets/images/favicon1.webp')} />
            </Helmet>
            <Navbar browserInfo={browserInfo} ipAddress={ipAddress}/>
            <section class="herosection" id="home">
                <div class="container">
                    <div class="row">
                        <div class="col-md-6">
                            <h1>No <span className="color-primary">One Size</span> Fits All</h1>
                            <p>Your requirement is the favourite part of our portfolio!</p>
                            <p>Work closely with us & see your idea grow from a prototype to a solution stack customized to your needs!</p>
                            <p>Does that sound like an exciting journey! </p>
                            <p>Let's dive in and start turning the vision into reality.</p>

                            <div className="hero-cta">
                                <button className="btn btn-primary" ><Link to="/contact-us" style={{ color: '#ffffff', textDecoration: 'none' }}>Contact us</Link></button>
                                <button className="btn btn-default" style={{ backgroundColor: 'white' }}><Link to="/services">Learn More</Link></button>
                            </div>
                            <div className="social-media">
                                <div onClick={() => window.open("https://www.facebook.com/people/Krisantec-Solutions/100089380740551/?mibextid=LQQJ4d", "_blank")}>
                                    <img alt="facebook" src={require('../assets/images/fb.png')} width="100%" className="social-icon" />
                                    <span>FACEBOOK</span>
                                </div>
                                <div onClick={() => window.open("https://www.instagram.com/krisantec_solutions?igsh=YjV1NXFmamRmeG5r", "_blank")}>
                                    <img alt="Instagram" src={require('../assets/images/insta.png')} width="100%" className="social-icon" />
                                    <span>Instagram</span>
                                </div>
                                <div onClick={() => window.open("https://www.linkedin.com/company/krisantec-solutions/?viewAsMember=true", "_blank")}>
                                    <img alt="Linkedin" src={require('../assets/images/linkedin.png')} width="100%" className="social-icon" />
                                    <span>Linkedin</span>
                                </div>
                                <div onClick={() => window.open("https://twitter.com/krisantec", "_blank")}>
                                    <img alt="x" src={require('../assets/images/x.png')} width="100%" className="social-icon" />
                                    <span>twitter</span>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6">
                            {/* <img alt="krisantec img" src={require('../assets/images/hero.webp')} width="100%" /> */}
                        </div>
                    </div>
                </div>
            </section>
            <section className="responsive_img">
                <img alt="krisantec img" src={require('../assets/images/hero_mobile.webp')} />
                <div className="container">
                    <div className="responsive-social-media">
                        <div onClick={() => window.open("https://www.facebook.com/krisantec/", "_blank")}>
                            <img alt="facebook" src={require('../assets/images/fb.png')} width="100%" className="social-icon" />
                            <span>FACEBOOK</span>
                        </div>
                        <div onClick={() => window.open("https://www.instagram.com/krisantec_solutions?igsh=YjV1NXFmamRmeG5r", "_blank")}>
                            <img alt="Instagram" src={require('../assets/images/insta.png')} width="100%" className="social-icon" />
                            <span>Instagram</span>
                        </div>
                        <div onClick={() => window.open("https://www.linkedin.com/company/krisantec-solutions/?viewAsMember=true", "_blank")}>
                            <img alt="Linkedin" src={require('../assets/images/linkedin.png')} width="100%" className="social-icon" />
                            <span>Linkedin</span>
                        </div>
                        <div onClick={() => window.open("https://twitter.com/krisantec", "_blank")}>
                            <img alt="x" src={require('../assets/images/x.png')} width="100%" className="social-icon" />
                            <span>twitter</span>
                        </div>
                    </div>
                </div>
            </section>
            <section class="why-us" id="about">
                <div class="container">
                    <div class="so-far">
                        <div className="row">
                            <div className="col-md-6">
                                <div className="row">
                                    <div class="col-6">
                                        <p class="count">20+</p>
                                        <p class="p-title">Web Applications</p>
                                    </div>
                                    <div class="col-6">
                                        <p class="count">10+</p>
                                        <p class="p-title">App Projects</p>
                                    </div>
                                </div></div>
                            <div className="col-md-6">
                                <div className="row">
                                    <div class="col-6">
                                        <p class="count">15+</p>
                                        <p class="p-title">Health Care Projects</p>
                                    </div>
                                    <div class="col-6">
                                        <p class="count">10</p>
                                        <p class="p-title">FinTech Projects</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row create-value">
                        <div class="col-md-6">
                            <img alt="krisantec img" src={require('../assets/images/hire.png')} />
                        </div>
                        <div class="col-md-6">
                            <h2 class="lasting-values">Create Lasting Value</h2>
                            <p>Innovation is the heart of our applications. We incorporate the latest technologies to deliver breakthrough solutions. Building innovative applications requires a combination of creativity, technical expertise, and strategic vision. We beleive in cultivating innovation throughout the app development lifecycle, from ideation and prototyping to testing and deployment.</p>
                            <Link className="btn btn-primary jumplink" to="/contact-us">Contact Us</Link>
                        </div>
                    </div>
                </div>
            </section>
            <section className="features">
                <div className="container">
                    <h2>Core Competencies</h2>
                    <div className="grid_container_3">
                        <div className="feature-item">
                            <img alt="krisantec img" src={require('../assets/images/core/new-age-tech.webp')} class="features-img" />
                            <div>New Age Tech</div>
                        </div>
                        <div className="feature-item">
                            <img alt="krisantec img" src={require('../assets/images/core/holistic-approach.webp')} class="features-img" />
                            <div>Holistic Approach</div>
                        </div>
                        <div className="feature-item">
                            <img alt="krisantec img" src={require('../assets/images/core/robust-execution-framework.webp')} class="features-img" />
                            <div>Robust Execution Framework</div>
                        </div>
                    </div>
                    <div className="grid_container_2">
                        <div className="feature-item">
                            <img alt="krisantec img" src={require('../assets/images/core/smarter-workforce.webp')} class="features-img" />
                            <div>Smarter Workforce</div>
                        </div>
                        <div className="feature-item">
                            <img alt="krisantec img" src={require('../assets/images/core/contextual-knowledge.webp')} class="features-img" />
                            <div>Contextual Knowledge</div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="ai">
                <div className="container">
                    <div className="bg_img">
                        <div className="row align-items-center">
                            <div className="col-md-6"></div>
                            <div className="col-md-6">
                                <h3>We incorporate innovative technology such as Automation, AI to deliver Application & Solutions that drive business growth</h3>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section class="banner-text">
                <div class="container">
                    <h4>Deploy The Best</h4>
                    <p>Our enterprise architects define streamlined self operating IT architecture to attain speed, and accuracy and prepare you to be future ready</p>
                </div>
            </section>
            <section class="services" id="services">
                <div class="container">
                    <h2>Industries We Serve</h2>
                    <div class="row">
                        <div class="col-md-3">
                            <div class="services-container">
                                <Link to="/services/business-domains/retail-software-system">
                                    <img alt="krisantec img" src={require('../assets/images/services/retail.png')} width="100%" />
                                    <div class="services-info">
                                        <h3>Retail</h3>
                                    </div>
                                </Link>
                            </div>
                        </div>
                        <div class="col-md-3">
                            <div class="services-container">
                                <Link to="/services/business-domains/e-commerce">
                                    <img alt="krisantec img" src={require('../assets/images/services/ecommerce.png')} width="100%" />
                                    <div class="services-info">
                                        <h3>E Commerce</h3>
                                    </div>
                                </Link>
                            </div>
                        </div>
                        <div class="col-md-3">
                            <div class="services-container">
                                <Link to="/services/business-domains/elearning">
                                    <img alt="krisantec img" src={require('../assets/images/services/education.png')} width="100%" />
                                    <div class="services-info">
                                        <h3>Education</h3>
                                    </div>
                                </Link>
                            </div>
                        </div>
                        <div class="col-md-3">
                            <div class="services-container">
                                <Link to="/services/business-domains/healthcare-it">
                                    <img alt="krisantec img" src={require('../assets/images/services/healthcare.png')} width="100%" />
                                    <div class="services-info">
                                        <h3>Healthcare</h3>
                                    </div>
                                </Link>
                            </div>
                        </div>
                        <div class="col-md-3">
                            <div class="services-container">
                                <Link to="/services/business-process/social-networking">
                                    <img alt="krisantec img" src={require('../assets/images/services/social.png')} width="100%" />
                                    <div class="services-info">
                                        <h3>Social</h3>
                                    </div>
                                </Link>
                            </div>
                        </div>
                        <div class="col-md-3">
                            <div class="services-container">
                                <Link to="/services/business-process/erp-automation">
                                    <img alt="krisantec img" src={require('../assets/images/services/realestate.png')} width="100%" />
                                    <div class="services-info">
                                        <h3>Real Estate</h3>
                                    </div>
                                </Link>
                            </div>
                        </div>
                        <div class="col-md-3">
                            <div class="services-container">
                                <Link to="/services/business-domains/information-technology">
                                    <img alt="krisantec img" src={require('../assets/images/services/it.png')} width="100%" />
                                    <div class="services-info">
                                        <h3>Information Technology</h3>
                                    </div>
                                </Link>
                            </div>
                        </div>
                        <div class="col-md-3">
                            <div class="services-container">
                                <Link to="/services/it-development-consulting">
                                    <img alt="krisantec img" src={require('../assets/images/services/ondemand.png')} width="100%" />
                                    <div class="services-info">
                                        <h3>On Demand Solutions</h3>
                                    </div>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section class="banner-text">
                <div class="container">
                    <h4>Fuelling Intelligent Decisions</h4>
                    <p>Drive agility using data analytics. We assist to add value to your business through monetization of data.</p>
                </div>
            </section>
            <section class="testimonials">
                <div class="container">
                    <h2>Discover Our Portfolio</h2>
                    <div className="row">
                        <div class="col-md-2">
                         <img alt="krisantec img" src={require('../assets/images/clients/apollo.webp')} class='slider-images' />
                           </div>
                             <div className="col-md-2">
                             <img alt="krisantec img" src={require('../assets/images/clients/rainbow.webp')} class='slider-images' />
                            </div>
                            <div class="col-md-2">
                            <img alt="krisantec img" src={require('../assets/images/clients/opoto.webp')} class='slider-images' />
                            </div>
                            <div class="col-md-2">
                            <img alt="krisantec img" src={require('../assets/images/clients/docbizz.webp')} class='slider-images' />
                            </div>
                            <div class="col-md-2">
                                    <img alt="krisantec img" src={require('../assets/images/clients/bookmyot.webp')} class='slider-images' />
                                </div>
                                 <div class="col-md-2">
                                    <img alt="krisantec img" src={require('../assets/images/clients/vharvest.webp')} class='slider-images' />
                                </div>
                            </div>
                            <div className="row">
                              <div class="col-md-2">
                                    <img alt="krisantec img" src={require('../assets/images/clients/freshby.webp')} class='slider-images' />
                                </div>
                             <div class="col-md-2">
                                    <img alt="krisantec img" src={require('../assets/images/clients/shopdiy.webp')} class='slider-images' />
                                  </div>
                                  <div class="col-md-2">
                                    <img alt="krisantec img" src={require('../assets/images/clients/meeatlogo.webp')} class='slider-images' />
                                  </div>
                                  <div class="col-md-2">
                                    <img alt="krisantec img" src={require('../assets/images/clients/leelalogo.webp')} class='slider-images' />
                                  </div>
                            </div>
                       </div>
                      
            </section>
            <section className="blog">
                <div className="container">
                    <h2>Blog's</h2>
                    <div className="row">
                        {blogs.length > 0 && blogs.map((blog, index) => (
                            <div className="col-md-6">
                                <div style={{ textAlign: 'left' }}>
                                    <div className="blog-image">
                                        <img src={blog?.image.image} />
                                    </div>
                                    <div className="px-2">
                                        <h3 className="truncate_lines_2 hover_link" onClick={() => handleBlogClick(blog)}>{blog.title}</h3>
                                        <p className="truncate_lines_3" dangerouslySetInnerHTML={{ __html: blog?.content }} style={{ textAlign: 'justify' }} />
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                    <button className="btn btn-primary" onClick={() => navigate('/blog')}>See More</button>
                </div>
            </section>
            <section className="success">
                <div className="container">
                    <div className="text-center">
                        <span>Testimonials</span>
                        <h2>Client’s success is our <br />success</h2>
                        <div className="items">
                            <Slider
                                ref={slider => {
                                    sliderRef = slider;
                                }}
                                {...settings2}
                            >
                                <div className="story-card">
                                    <div>
                                        <div className="story-image">
                                            <img src={require('../assets/images/malli.jpeg')} style={{ width: '100%', height: '100%', borderRadius: '50%', zIndex: 0 }} />
                                            <div className="quotes">
                                                <img src={require('../assets/images/quotes.png')} style={{ width: '14px' }} />
                                            </div>
                                        </div>
                                        <div className="story-text">
                                            <p>
                                                “ The success of a startup hinges on the optimal utilization
                                                of resources. KriSanTec has played a crucial role in helping
                                                us achieve our targeted goals with their cost-effective Smart
                                                SCM and B2B Sales App. Their innovative approach and methodologies,
                                                combined with their commitment to delivering high-quality technology,
                                                have been invaluable to startups like ours. 😊. ”
                                            </p>
                                        </div>
                                        <div>
                                            <h3 className="story-by">Mallikarjun</h3>
                                            <p className="story-name">Founder V Harvest</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="story-card">
                                    <div>
                                        <div className="story-image">
                                            <img src={require('../assets/images/blog1.png')} style={{ width: '100%', height: '100%', borderRadius: '50%', zIndex: 0 }} />
                                            <div className="quotes">
                                                <img src={require('../assets/images/quotes.png')} style={{ width: '14px' }} />
                                            </div>
                                        </div>
                                        <div className="story-text">
                                            <p>
                                                “ Developing an ERP system for seamless, end-to-end operations that aligns with futuristic
                                                goals is a daunting task, especially for niche domains like Teleradiology Solutions.
                                                However, Krisantec's founder brought a wealth of experience and enthusiasm to the table,
                                                alleviating our concerns and delivering a robust ERP solution. The founder's passion for
                                                innovation and technology instilled confidence in us, and we knew we were in capable hands.
                                                Thanks to Krisantec, we now boast an ERP system that harmonizes our operations, boosts efficiency,
                                                and supports our long-term business objectives, paving the way for sustainable growth and success. 😊. ”
                                            </p>
                                        </div>
                                        <h3 className="story-by">Dr Vamsi</h3>
                                        <p className="story-name">CGO Apollo Teleradiology</p>
                                    </div>
                                </div>
                            </Slider>
                            <div className="home-btn-pair">
                                <button className="btn" onClick={previous}>
                                    <GoArrowLeft color="#2362B6" />
                                </button>
                                <button className="btn" onClick={next}>
                                    <GoArrowRight color="#2362B6" />
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section class="faqs">
                <div class="container">
                    <h2>FAQ'S</h2>
                    <p class="faqs-quote">Frequently asked questions from many people.</p>
                    <div class="accordion" id="accordionExample">
                        <div class="accordion-item">
                            <h3 class="accordion-header" id="headingOne">
                                <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                    How much do you charge for one project ?
                                </button>
                            </h3>
                            <div id="collapseOne" class="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                <div class="accordion-body">
                                    That totally depends on the project, How much time will it take, What technology we are using, and the features required in the project.
                                </div>
                            </div>
                        </div>
                        <div class="accordion-item">
                            <h3 class="accordion-header" id="headingTwo">
                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                    When will you provide the code ?
                                </button>
                            </h3>
                            <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                                <div class="accordion-body">
                                    Acc. to our policy we will only provide the full code of the app after full payment. For testing purposes, we will provide an unsigned APK.
                                </div>
                            </div>
                        </div>
                        <div class="accordion-item">
                            <h3 class="accordion-header" id="headingThree">
                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                    How will you protect the code ?
                                </button>
                            </h3>
                            <div id="collapseThree" class="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                                <div class="accordion-body">
                                    We will never share the code with any other client. Every repository of the code will be private and only the developer and client will have access to that.
                                </div>
                            </div>
                        </div>
                        <div class="accordion-item">
                            <h3 class="accordion-header" id="headingFour">
                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                                    What payment methods you accept ?
                                </button>
                            </h3>
                            <div id="collapseFour" class="accordion-collapse collapse" aria-labelledby="headingFour" data-bs-parent="#accordionExample">
                                <div class="accordion-body">
                                    We accept almost all payment methods like UPI, Paytm, PhonePe, GPay, Bank transfer, Paypal, Stripe, etc.
                                </div>
                            </div>
                        </div>
                        <div class="accordion-item">
                            <h3 class="accordion-header" id="headingFive">
                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
                                    What technologies do you specialize in ?
                                </button>
                            </h3>
                            <div id="collapseFive" class="accordion-collapse collapse" aria-labelledby="headingFive" data-bs-parent="#accordionExample">
                                <div class="accordion-body">
                                    As an IT development company, our specialization often depends on the specific expertise of our team members and the needs of our clients. However, here are some common technologies and platforms that we specialize in:
                                    <br /><b>Web Development:</b><br />Frontend: HTML5, CSS3, Bootstrap, JavaScript (React.js,React Native)<br />
                                    Backend:  Python, Django, Flask<br />
                                    <b> Mobile Development:</b><br />
                                    iOS: Swift, Objective-C<br />
                                    Android: React Native, Python<br />
                                    Cross-platform: React Native, Flutter<br />
                                    <b>Database Management:</b><br />
                                    SQL databases: MySQL, PostgreSQL, Microsoft SQL Server<br />
                                    <b>Cloud Computing:</b><br />
                                    Amazon Web Services (AWS)<br />
                                    <b>E-commerce Platforms:</b><br />
                                    ShopDiy<br />
                                    However our specialization can vary depending on project requirements and the evolving landscape of technology. We strive to stay updated with emerging technologies to provide innovative solutions to our clients.
                                </div>
                            </div>
                        </div>
                        <div class="accordion-item">
                            <h3 class="accordion-header" id="headingSix">
                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseSix" aria-expanded="false" aria-controls="collapseSix">
                                    How do you ensure the security of the projects you work on ?
                                </button>
                            </h3>
                            <div id="collapseSix" class="accordion-collapse collapse" aria-labelledby="headingSix" data-bs-parent="#accordionExample">
                                <div class="accordion-body">
                                    Ensuring the security of the projects we work on is a top priority for our IT development company. Here are some key measures we take to uphold security standards:
                                    <br /><b>Risk Assessment:</b>
                                    <br />We conduct comprehensive risk assessments at the outset of each project to identify potential security vulnerabilities and threats.
                                    <br /><b>Secure Development Lifecycle:</b>
                                    <br />Our development process incorporates security measures at every stage, from design and coding to testing and deployment.
                                    <br /><b>Data Encryption:</b>
                                    <br />We implement encryption mechanisms, such as SSL/TLS for data transmission and encryption algorithms for data storage, to protect sensitive information.
                                    <br /><b>Access Control and Authentication:</b>
                                    <br />We enforce strict access control measures and implement robust authentication mechanisms to ensure that only authorized users can access the system.
                                    <br /><b>Client Collaboration:</b>
                                    <br />We maintain open communication with our clients regarding security concerns and involve them in decision-making processes related to security measures.
                                    <br /><br />By implementing these measures, we strive to build secure, reliable, and resilient systems that safeguard our clients' data and assets against potential threats and vulnerabilities.
                                </div>
                            </div>
                        </div>
                        <div class="accordion-item">
                            <h3 class="accordion-header" id="headingSeven">
                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseSeven" aria-expanded="false" aria-controls="collapseSeven">
                                    What is your pricing structure ?
                                </button>
                            </h3>
                            <div id="collapseSeven" class="accordion-collapse collapse" aria-labelledby="headingSeven" data-bs-parent="#accordionExample">
                                <div class="accordion-body">
                                    Our pricing structure is tailored to the specific needs and requirements of each project. We understand that every project is unique, and factors such as complexity, scope, technology stack, timeline, and desired level of customization can all influence the cost.
                                    <br />However, we typically offer one or a combination of the following pricing models:
                                    <br /><b>Fixed Price:</b>
                                    <br />Under this model, we agree upon a fixed price for the entire project based on the project requirements and scope defined during the initial consultation. This pricing model works well for projects with clearly defined requirements and a stable scope.
                                    <br /><b>Time and Materials (T&M):</b>
                                    <br />With the time and materials model, the client pays based on the actual time spent by our team members and the materials (such as software licenses or third-party services) used during the project. This model offers flexibility, especially for projects with evolving requirements or where the scope is subject to change.
                                    <br /><b>Milestone-Based:</b>
                                    <br />For longer-term projects, we may structure the pricing based on project milestones. Payments are tied to the completion of predefined milestones or deliverables, providing transparency and accountability throughout the project lifecycle.
                                    <br /><b>Hybrid Models:</b>
                                    <br />Depending on the project's complexity and requirements, we may propose a hybrid pricing model that combines elements of fixed-price, time and materials, or milestone-based pricing to best suit the client's needs.
                                    <br /><br />During our initial consultation, we work closely with the client to understand their project goals, requirements, and budget constraints. Based on this information, we propose a pricing structure that aligns with their expectations and ensures a fair and transparent pricing arrangement.
                                    <br /><br />Additionally, we provide detailed estimates and breakdowns of costs, allowing clients to make informed decisions and prioritize features based on their budget and timeline. Our goal is to provide value-driven pricing that reflects the quality of our work and the outcomes achieved for our clients.
                                </div>
                            </div>
                        </div>
                        <div class="accordion-item">
                            <h3 class="accordion-header" id="headingEight">
                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseEight" aria-expanded="false" aria-controls="collapseEight">
                                    Do you offer any additional services, such as UX/UI design or digital marketing?
                                </button>
                            </h3>
                            <div id="collapseEight" class="accordion-collapse collapse" aria-labelledby="headingEight" data-bs-parent="#accordionExample">
                                <div class="accordion-body">
                                    Yes, we offer additional services beyond IT development, including UX/UI design and digital marketing. These services complement our core development offerings and provide a comprehensive solution to meet our clients' needs. Here's an overview of the additional services we offer:
                                    <br /><b>UX/UI Design:</b>
                                    <br />Our UX/UI design services focus on creating intuitive, user-friendly interfaces that enhance the overall user experience of digital products and applications. Our team of designers collaborates closely with clients to understand their target audience, brand identity, and project goals, and then crafts visually appealing and functional designs that meet these requirements. We offer services such as wireframing, prototyping, user testing, and interface design to ensure that the final product aligns with our clients' vision and exceeds user expectations.
                                    <br /><b>Digital Marketing:</b>
                                    <br />In addition to development and design, we provide digital marketing services to help clients promote their products and reach their target audience effectively. Our digital marketing team offers a range of services, including search engine optimization (SEO), pay-per-click (PPC) advertising, social media marketing, content marketing, email marketing, and analytics. We tailor our digital marketing strategies to each client's unique needs and objectives, helping them increase visibility, drive traffic, generate leads, and ultimately achieve their business goals.
                                    <br /><br />By offering these additional services, we provide clients with a comprehensive solution for their digital projects, covering everything from initial concept and design to development, promotion, and ongoing optimization. This integrated approach ensures seamless collaboration, consistent branding, and maximum impact for our clients' digital initiatives.
                                </div>
                            </div>
                        </div>
                        <div class="accordion-item">
                            <h3 class="accordion-header" id="headingNine">
                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseNine" aria-expanded="false" aria-controls="collapseNine">
                                    Do you provide ongoing support and maintenance after the project is completed?
                                </button>
                            </h3>
                            <div id="collapseNine" class="accordion-collapse collapse" aria-labelledby="headingNine" data-bs-parent="#accordionExample">
                                <div class="accordion-body">
                                    Yes, we provide ongoing support and maintenance services to ensure the continued smooth operation and optimal performance of the projects we deliver. Our support and maintenance services are designed to address any issues, updates, or enhancements that may arise after the project is completed. Here's an overview of what our ongoing support and maintenance services typically include:
                                    <br /><b>Bug Fixes and Issue Resolution:</b>
                                    <br />We promptly address any bugs, errors, or issues that may arise in the software after deployment. Our team investigates reported issues, identifies the root cause, and implements appropriate fixes to restore functionality and stability.
                                    <br /><b>Software Updates and Upgrades:</b>
                                    <br />We ensure that the software is kept up to date with the latest versions of libraries, frameworks, and dependencies to maintain compatibility, security, and performance.
                                    <br /><b>Performance Monitoring and Optimization:</b>
                                    <br />This includes monitoring resource utilization, response times, and user feedback to optimize performance and scalability.
                                    <br /><b>Security Maintenance:</b>
                                    <br />This includes applying security patches, conducting security audits, and implementing additional security controls to mitigate risks and ensure data integrity and confidentiality.
                                    <br /><b>Data Backup and Disaster Recovery:</b>
                                    <br />We regularly back up critical data and establish recovery procedures to minimize downtime and data loss.
                                    <br /><b>User Support and Training:</b>
                                    <br />We provide ongoing user support and training to help users effectively utilize the software and address any questions or issues they may encounter. This includes providing documentation, FAQs, tutorials, and user training sessions to empower users and enhance their experience.
                                    <br /><b>Feature Enhancements and Additions:</b>
                                    <br />We collaborate with clients to identify opportunities for feature enhancements or additions based on evolving business needs, user feedback, or industry trends.
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </section>
            <section class="contact" id="contact">
                <div class="container">
                    <h2>Our Work Speaks For Us</h2>
                    <p class="contact-quote">Do You Have A Project That You Would Like To Discuss On? Talk To Our Expert</p>
                    <div class="row">
                        <div class="col-md-6">
                            <div class="row">
                                <div class="col-md-6">
                                    <div class="email">
                                        <MaterialIcon icon="mail" size={40} color={"#0d53ad"} />
                                        <h3>Email</h3>
                                        <p><a href="mailto:info@krisantec.com" className="anchor-text">info@krisantec.com</a></p>
                                        <br />
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="phone">
                                        <MaterialIcon icon="phone_in_talk" size={40} color={"#0d53ad"} />
                                        <h3>Call</h3>
                                        <p><a href={`tel:+91 8712874770`} className="anchor-text">+91 8712874770</a></p>
                                        <br />
                                    </div>
                                </div>
                            </div>
                            <div class="address">
                                <MaterialIcon icon="pin_drop" size={40} color={"#0d53ad"} />
                                <h3>Address</h3>
                                <p>Suraj Mansion, #202, Shyam Karan Rd, Leelanagar, Hyderabad, Telangana 500016</p><br />
                                <button type="submit" class="btn btn-primary"><a href="https://www.google.com/maps/dir/?api=1&destination=17.436851691041547,78.4529135003686&travelmode=driving" target="_blank" style={{ color: '#ffffff', textDecoration: 'none' }}>Get Directions</a></button>
                                <br />
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="box-with-shadow">
                                <h2>Submit Your Query</h2>
                                {/* <form action="">
                                    <div class="mb-3 mt-3">
                                        <label for="name">Name:</label>
                                        <input type="name" class="form-control" id="name" placeholder="Enter Name" name="name" />
                                    </div>
                                    <div class="mb-3 mt-3">
                                        <label for="contact">Contact No:</label>
                                        <input type="contact" class="form-control" id="contact" placeholder="Enter Contact No "
                                            name="contact" />
                                    </div>
                                    <div class="mb-3 mt-3">
                                        <label for="email">Email:</label>
                                        <input type="email" class="form-control" id="email" placeholder="Enter Email" name="email" />
                                    </div>
                                    <div class="mb-3 mt-3">
                                        <label for="query">Query:</label>
                                        <textarea class="form-control" rows="2" id="query" name="query"></textarea>
                                    </div>
                                    <button type="submit" class="btn btn-primary">Submit</button>
                                </form> */}
                                <ContactForm />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="newproject">
                <div className="ellipse_top"></div>
                <div class="container">
                    <p>Let's Work Together</p>
                    <h3>Turn your vision<br /> into reality</h3>
                    <button className="btn btn-default" style={{ backgroundColor: 'white' }}><Link to="/contact-us">Let's collaborate</Link></button>
                </div>
                <div className="ellipse_bottom"></div>
            </section>
            <Footer />
        </div>
    );
};

export default Home;