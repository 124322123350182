import React, { useState, useEffect, useRef } from 'react';
import { FaPaperclip, FaTimes, FaComments, FaUserCircle } from 'react-icons/fa';
import axiosConfig from '../Service/axiosConfig';
import './ChatBot.css';
import { useGlobalContext } from '../components/GlobalProvider';

export const ChatBot = () => {
    const { setIpAddress, setBrowserInfo, ipAddress, browserInfo } = useGlobalContext();
    const [messages, setMessages] = useState([]);
    const [input, setInput] = useState('');
    const [isChatVisible, setIsChatVisible] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [parent_id, setParentId] = useState(null);
    const chatBodyRef = useRef(null);
    const [inputType, setInputType] = useState('');
    const [Type, setType] = useState('');
    const [isBack, setIsBack] = useState('');
    const [shouldScrollToBot, setShouldScrollToBot] = useState(false);
    const [chatLogo, setCahtLogo] = useState({})
    const lastBotMessageRef = useRef(null);
    useEffect(() => {
        async function getChatLogo() {
            try {
                const response = await axiosConfig.get('/message_framework/chatlogo/');
                setCahtLogo(response.data?.results[0])
            } catch (error) {
                console.error("Failed to fetch chat logo:", error);
                throw error;
            }
        }
        const fetchMessages = async () => {
            try {
                const response = await axiosConfig.get('/message_framework/chat/');
                const data = response.data;

                setMessages([
                    { text: data.welcome_message.message, sender: 'bot' },
                    { text: data.next_message.message, sender: 'bot' },
                    ...data.next_message.content_attributes.items.map(option => ({
                        parent: option.id,
                        text: option.title,
                        sender: 'option',
                        value: option.value,
                        isSelected: false,
                    }))
                ]);
            } catch (error) {
                console.error('Error fetching messages:', error);
            }
        };
        fetchMessages();
        getChatLogo();
    }, []);
    useEffect(() => {
        const lastBotMessage = messages.filter(msg => msg.sender === 'bot').pop(); // Get the last bot message
        if (lastBotMessageRef.current && lastBotMessage) {
            lastBotMessageRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
        }
    }, [messages]);
    const handleSelection = async (value, label, id) => {
        if (value === "/exit_chat") {
            exitChat();
            return;
        }

        try {
            const response = await axiosConfig.post('/message_framework/chat/', {
                selected_value: value,
                ipaddress: ipAddress,
                browser_type: browserInfo
            });

            // Update the messages based on the previous state
            setMessages(prevMessages => {
                // Initialize a new array for updated messages
                const updatedMessages = [];

                // Iterate through the previous messages to update selected options
                prevMessages.forEach(msg => {
                    if (msg.sender === 'option') {
                        // Mark selected options
                        updatedMessages.push({ ...msg, isSelected: true });
                    } else {
                        updatedMessages.push(msg);
                    }
                });

                // Add the user message
                if(value !=="/back"){
                updatedMessages.push({ text: label, sender: 'user' });
                }

                // Process bot messages and options
                response.data.content.forEach(content => {
                    if (content.title) {
                        console.log(content.title)
                        setInputType(content.title);
                        setType(content.input_type)
                    }
                    setIsBack(content?.is_back)

                    updatedMessages.push({ text: content.message, sender: 'bot' });
                    (content.content_attributes?.items || []).forEach(option => {
                        updatedMessages.push({
                            text: option.title,
                            sender: 'option',
                            value: option.value,
                            isSelected: false
                        });
                    });
                });

                // Return the updated messages array
                return updatedMessages;
            });

            // Scroll to the last bot message
            if (lastBotMessageRef.current) {
                lastBotMessageRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
            }
            // Set the parent ID
            setParentId(id);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };



    const handleSendMessage = async (e) => {
        e.preventDefault();
        if (input.trim() === '') return;

        const userMessage = input;
        setInput(''); // Clear the input field
        setIsBack("")
        setMessages(prevMessages => [...prevMessages, { text: userMessage, sender: 'user', parent: parent_id }]);

        try {
            const response = await axiosConfig.post('/message_framework/chat/', {
                description: userMessage,
                ipaddress: ipAddress,
                browser_type: browserInfo,
                selected_value: inputType,
            });

            // Update messages based on the response
            setMessages(prevMessages => [
                ...prevMessages,
                ...response.data.next_message.flatMap(msg => {
                    setInputType(msg?.title);
                    setType(msg?.input_type);
                    // Push the bot message
                    const botMessage = { text: msg.message, sender: 'bot' };

                    // Push the options if they exist
                    const options = (msg.content_attributes?.items || []).map(option => ({
                        parent: option.id,
                        text: option.title,
                        sender: 'option',
                        value: option.value,
                        isSelected: false,
                    }));

                    return [botMessage, ...options]; // Combine bot message and options into a single array
                }),
            ]);
            // Scroll to the bottom of the chat
            if (chatBodyRef.current) {
                chatBodyRef.current.scrollTop = chatBodyRef.current.scrollHeight;
            }
        } catch (error) {
            console.error('Error sending message:', error);
            setMessages(prevMessages => [
                ...prevMessages,
                { text: error.response?.data?.message, sender: 'bot' },
            ]);
        }
    };


    const toggleChat = () => setIsChatVisible(!isChatVisible);
    const exitChat = () => {
        setIsChatVisible(false);
        setInput('');
    };

    return (
        <>
            {isChatVisible && (
                <div className="chat-container">
                    <div className="chat-header">
                        <div className="avatar-container">
                            <img src={chatLogo?.logo} alt="Diva" className="avatar" />
                            <div className="status-indicator"></div> {/* Add this line for the green dot */}
                        </div>
                        <div className="header-text">
                            <h2>{chatLogo?.title}</h2>
                            <p>{chatLogo?.sub_title}</p>
                        </div>
                        <button className="close-button" onClick={exitChat}>
                            <FaTimes />
                        </button>
                    </div>
                    <div className="chat-body" ref={chatBodyRef}>
                        {messages.map((msg, index) => (
                            <div key={index} ref={msg.sender === 'bot' ? lastBotMessageRef : null}>
                                {msg.sender === 'bot' && (
                                    <div className="message bot">
                                        <div className="message-content">
                                            <FaUserCircle className="bot-icon" size={20} />
                                            <span>{msg.text}</span>
                                        </div>
                                    </div>
                                )}
                                {msg.sender === 'user' && (
                                    <div className="message user">
                                        <div className="message-content">
                                            <span>{msg.text}</span>
                                        </div>
                                    </div>
                                )}
                            </div>
                        ))}

                        {isLoading && (
                            <div className="message bot">
                                <div className="message-content">
                                    <p className="loading">Bot is typing...</p>
                                </div>
                            </div>
                        )}

                        {/* Options section at the bottom */}
                        <div className="options-section" style={{ display: 'flex', flexWrap: 'wrap', gap: '10px', marginTop: '10px' }}>
                            {isBack && (
                                <div className="back-button-container">
                                    <span
                                        className="option"
                                        onClick={() => handleSelection('/back', 'Back')}
                                    >
                                        Back
                                    </span>
                                </div>
                            )}
                            {messages
                                .filter(msg => msg.sender === 'option' && !msg.isSelected)
                                .map((msg, index) => (
                                    <span
                                        key={index}
                                        className="option"
                                        onClick={() => handleSelection(msg.value, msg.text, msg.parent)}
                                    >
                                        {msg.text}
                                    </span>
                                ))}
                        </div>
                    </div>
                    {Type && <form className="chat-input" onSubmit={handleSendMessage}>
                        <button type="button" className="attachment-button">
                            <FaPaperclip />
                        </button>
                        <input
                            type={Type}
                            value={input}
                            onChange={(e) => setInput(e.target.value)}
                            placeholder="Type your message..."
                        />
                        <button type="submit">Send</button>
                    </form>}
                </div >
            )}
            {
                !isChatVisible && (
                    <button className="chat-toggle" onClick={toggleChat}>
                        <FaComments size={24} /> {/* Only the icon, you can adjust size if needed */}
                    </button>
                )
            }
        </>
    );
};