import React from "react";
import Navbar from '../../components/Navbar';
import Footer from '../../components/Footer';
import BreadCrumb from '../../components/BreadCrumb';

const ShoppingCart = () => {
    return (
        <div>
            <Navbar />
                <div class="container">
                    <BreadCrumb 
                        active_page="Shopping Cart"
                        prev_sub_page={{
                            'link':'/services',
                            'text':'Services'
                        }} 
                        sub_page={{
                            'link':'/services/business-process',
                            'text':'Business Process'
                        }} 
                    />
                    <div class="inner-container">
                        <h2>Shopping Cart</h2>
                        <h3>Your Right Choice</h3>
                        <p>Build a robust store in line with your niche. We possess ingenious expertise to develop solutions that precisely reflect your business needs. Made using the state of art technologies we offer seamless navigation to users.</p>
                        <h3>Deliver The Best</h3>
                        <p>Our innovative cart development solutions would help you market your products and services with ease. The solutions we offer are interoperable therefore there is always a room for expansion.</p>
                        <h3>How We Do</h3>
                        <img alt="" src={require('../../assets/images/services/shopping-cart.png')} className="d-block m-auto mw-60 mb-5" />
                        <h3>Our Solutions</h3>
                        <h3>We Focus On</h3>
                        <ul>
                            <li>Shopping Cart Migration Services</li>
                            <li>Open Source Shopping Cart Integration</li>
                            <li>Custom Shopping Cart Abandonment Solutions</li>
                            <li>E Commerce Shopping Cart Design</li>
                        </ul>
                        <ul>
                            <li>Easy Management</li>
                            <li>Usability</li>
                            <li>Security</li>
                            <li>Reliability</li>
                        </ul>
                    </div>
                </div>
            <Footer />
        </div>
    );
};

export default ShoppingCart;